import React, { ChangeEvent, FC, useReducer } from "react";
import ReactMarkdown from "react-markdown";
import { Popup } from "@/components/basic/Popup";
import { ProductCard } from "@/components/ProductCard";
import { isEmailValid } from "@/utils/validation";
import { sendEmail } from "@/services/productCardService";
import {
  addContactToTheList,
  alreadySubscribedStatusCode,
} from "@/services/mailingListService";
import {
  successTitle,
  failureTitle,
  successEmailMessage,
  successNewsletterMessage,
  failureEmailMessage,
  failureNewsletterMessage,
} from "@/utils/popupMessages";
import {
  ActionType,
  initialState,
  productCardReducer,
} from "@/reducers/productCardReducer";

interface ProductCardWrapperProps {
  sendGridTemplateId: string;
  descriptionTitle: string;
  mobileHeroImageUrl?: string;
  heroImageUrl: string;
  shortDescription?: string;
  showProductCardBtn?: boolean;
}

export const ProductCardWrapper: FC<ProductCardWrapperProps> = ({
  sendGridTemplateId,
  descriptionTitle,
  mobileHeroImageUrl,
  heroImageUrl,
  shortDescription,
  showProductCardBtn,
}) => {
  const [state, dispatch] = useReducer(productCardReducer, initialState);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch({
      type: ActionType.inputChange,
      payload: { email: value, isFormEnabled: isEmailValid(value) },
    });
  };

  const sendProductCardEmail = async () => {
    try {
      await sendEmail(sendGridTemplateId, state.email);
      dispatch({
        type: ActionType.successMessage,
        payload: successEmailMessage,
      });
    } catch {
      dispatch({
        type: ActionType.failureMessage,
        payload: failureEmailMessage,
      });
    }
  };

  const subscribeToNewsletter = async () => {
    try {
      await addContactToTheList(state.email);
      dispatch({
        type: ActionType.successMessage,
        payload: successNewsletterMessage,
      });
    } catch (error) {
      error.response.status === alreadySubscribedStatusCode
        ? dispatch({
            type: ActionType.successMessage,
            payload: successNewsletterMessage,
          })
        : dispatch({
            type: ActionType.failureMessage,
            payload: failureNewsletterMessage,
          });
    }
  };

  const handleSubmit = async () => {
    dispatch({ type: ActionType.submitStart });

    await sendProductCardEmail();
    state.isSubscribedToNewsletter && (await subscribeToNewsletter());

    dispatch({ type: ActionType.submitFinish });
  };

  return (
    <>
      <Popup
        title={successTitle}
        messages={state.successMessage}
        isOpen={!!state.successMessage.length}
        handleClose={() => dispatch({ type: ActionType.resetSuccessMessage })}
        variant="success"
      />
      <Popup
        title={failureTitle}
        messages={state.failureMessage}
        isOpen={!!state.failureMessage.length}
        showIcon={false}
        handleClose={() => dispatch({ type: ActionType.resetFailureMessage })}
        variant="failure"
      />
      <ProductCard
        title={descriptionTitle}
        mobileHeroImageUrl={mobileHeroImageUrl}
        heroImageUrl={heroImageUrl}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        handleCheckboxChange={() =>
          dispatch({ type: ActionType.checkboxChange })
        }
        email={state.email}
        isFormEnabled={state.isFormEnabled}
        showProductCardBtn={showProductCardBtn}
      >
        {shortDescription && <ReactMarkdown>{shortDescription}</ReactMarkdown>}
      </ProductCard>
    </>
  );
};
