export enum ActionType {
  inputChange = "INPUT_CHANGE",
  checkboxChange = "CHECKBOX_CHANGE",
  successMessage = "SUCCESS_MESSAGE",
  failureMessage = "FAILURE_MESSAGE",
  resetSuccessMessage = "RESET_SUCCESS_MESSAGE",
  resetFailureMessage = "RESET_FAILURE_MESSAGE",
  submitStart = "SUBMIT_START",
  submitFinish = "SUBMIT_FINISH",
}

export const initialState = {
  isFormEnabled: false,
  email: "",
  isSubscribedToNewsletter: false,
  successMessage: [],
  failureMessage: [],
};

export const productCardReducer = (state, action) => {
  switch (action.type) {
    case ActionType.inputChange:
      return {
        ...state,
        email: action.payload.email,
        isFormEnabled: action.payload.isFormEnabled,
      };
    case ActionType.checkboxChange:
      return {
        ...state,
        isSubscribedToNewsletter: !state.isSubscribedToNewsletter,
      };
    case ActionType.successMessage:
      return {
        ...state,
        successMessage: [...state.successMessage, action.payload],
      };
    case ActionType.failureMessage:
      return {
        ...state,
        failureMessage: [...state.failureMessage, action.payload],
      };
    case ActionType.resetSuccessMessage:
      return { ...state, successMessage: [] };
    case ActionType.resetFailureMessage:
      return { ...state, failureMessage: [] };
    case ActionType.submitStart:
      return {
        ...state,
        isFormEnabled: false,
        successMessage: [],
        failureMessage: [],
      };
    case ActionType.submitFinish:
      return {
        ...state,
        email: "",
        isSubscribedToNewsletter: false,
      };
    default:
      throw new Error();
  }
};
