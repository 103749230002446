import React, { FC } from "react";
import { MailingListTooltip } from "@/components/MailingListTooltip";
export interface NewsletterFormProps {
  onClose: () => void;
}

export const NewsletterForm: FC<NewsletterFormProps> = ({ onClose }) => (
  <div className="fixed-action-followup color-bg-negative l-with-gutters-mobile">
    <div className="flex-box flex-box-space-between">
      <div className="l-push-after">Sign up for the Newsletter</div>
      <div
        onClick={onClose}
        className="text-strong color-primary l-half-push-bottom l-bleed clickable-element align-end"
      >
        ╳ CLOSE
      </div>
    </div>
    <div id="mc_embed_signup">
      <form
        action="https://anixe.us7.list-manage.com/subscribe/post?u=56d89f67510e082173fdd156c&amp;id=46019cfa4c"
        className="validate default-form l-half-push-bottom l-bleed align-left"
        id="mc-embedded-subscribe-form"
        method="post"
        name="mc-embedded-subscribe-form"
        noValidate
        target="_blank"
      >
        <div className="clear" id="mce-responses"></div>
        <div
          className="response"
          id="mce-error-response"
          style={{ display: "none" }}
        ></div>
        <div
          className="response"
          id="mce-success-response"
          style={{ display: "none" }}
        ></div>
        <div
          aria-hidden="true"
          style={{ position: "absolute", left: "-5000px" }}
        >
          <input
            name="b_56d89f67510e082173fdd156c_46019cfa4c"
            tabIndex={-1}
            type="text"
          />
        </div>
        <div className="flex-box">
          <input
            className="required email newsletter-input"
            id="mce-EMAIL"
            name="EMAIL"
            placeholder="Enter Your Email"
            required={true}
            type="email"
          />
          <button
            className="button button-primary"
            id="mc-embedded-subscribe"
            name="subscribe"
            type="submit"
            value="Subscribe"
          >
            Subscribe
          </button>
        </div>
        <div className="text-12">
          Yes, I want to receive from ANIXE Polska Sp. z o.o. marketing
          information in the form of a Newsletter. For this purpose, I give my
          e-mail address above.
          <MailingListTooltip />
        </div>

        <div className="text-12">
          <input
            id="mce-group[78161]-78161-0"
            name="group[78161][1]"
            type="checkbox"
            value="1"
          />
          Yes, I want to receive from ANIXE Polska Sp. z o.o. also other
          information and offers to the above e-mail address. Add me to your
          mailing list.
          <MailingListTooltip />
        </div>
      </form>
    </div>
  </div>
);
