import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface CaseStudyProps {
  imageUrl: string;
  imageAlt: string;
  description: string;
}

export const CaseStudy: FC<CaseStudyProps> = ({
  imageUrl,
  imageAlt,
  description,
}) => (
  <li className="case-study">
    <div className="l-wrapper">
      <div className="case-study-content l-8-cols-desktop l-full l-island clearfix">
        <div className="image-wrapper">
          <img className="image" src={imageUrl} alt={imageAlt} />
        </div>
        <div className="text-wrapper l-island clearfix">
          <ReactMarkdown allowDangerousHtml>{description}</ReactMarkdown>
          <div className="contact-message">
            <div className="l-push-top l-push-bottom">
              Interested in similar project?
            </div>
            <div className="l-push-top">
              <a
                className="button button-primary-negative-dark"
                href="/contact#contact-form"
              >
                Send us a message
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
);
