import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface HighlightBoxesProps {
  markdown: string;
  title?: string;
  columns?: number;
  className?: string;
}
export const HighlightBoxes: FC<HighlightBoxesProps> = ({
  title,
  markdown,
  columns = 10,
  className = "",
}) => (
  <div className="l-wrapper">
    <div
      className={`l-${columns}-cols-tablet highlight-boxes l-island ${className}`}
    >
      {title && (
        <h3 className="color-primary align-center l-push-bottom">{title}</h3>
      )}
      <ReactMarkdown allowDangerousHtml={true}>{markdown}</ReactMarkdown>
    </div>
  </div>
);
