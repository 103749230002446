import React, { FC, useState, ChangeEvent, MouseEvent } from "react";
import { Hero } from "@/components/Hero";
import { MailingListTooltip } from "@/components/MailingListTooltip";
import { MailingListCheckbox } from "@/components/MailingListCheckbox";
import { Input } from "@/components/basic/Input";

interface ProductCardBaseProps {
  handleSubmit: () => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: () => void;
  isFormEnabled: boolean;
  email: string;
}
interface ProductCardProps extends ProductCardBaseProps {
  title: string;
  mobileHeroImageUrl?: string;
  heroImageUrl: string;
  showProductCardBtn?: boolean;
}
interface ProductCardFormProps extends ProductCardBaseProps {
  closeForm: () => void;
}

const ProductCardForm: FC<ProductCardFormProps> = ({
  closeForm,
  handleSubmit,
  handleInputChange,
  handleCheckboxChange,
  email,
  isFormEnabled,
}) => {
  const onSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  return (
    <>
      <div className="label-block">
        <span className="color-negative">
          Enter email address and we&quot;ll send you a link
        </span>
      </div>
      <form className="default-form flex-box flex-dir-column">
        <div>
          <Input
            name="email"
            classname="required email newsletter-input l-half-push-after"
            value={email}
            handleChange={handleInputChange}
            placeholder="Enter email address"
          />
          <button
            className="button button-ghost-negative v-align-top"
            type="submit"
            name="subscribe"
            onClick={onSubmit}
            disabled={!isFormEnabled}
          >
            SUBMIT
          </button>
          <button
            className="button button-inline-negative"
            type="button"
            onClick={closeForm}
          >
            <span className="hidden-mobile hidden-tablet v-align-top">╳</span>{" "}
            Cancel
          </button>
        </div>
        <div className="l-half-push-top l-bleed text-12">
          Yes, I want to receive Product Card from ANIXE Polska Sp. z o.o. For
          this purpose I give my e-mail address above.
          <MailingListTooltip />
        </div>
        <div className="l-half-push-top l-bleed text-12">
          <MailingListCheckbox handleChange={handleCheckboxChange} />
        </div>
      </form>
    </>
  );
};

export const ProductCard: FC<ProductCardProps> = ({
  title,
  mobileHeroImageUrl,
  heroImageUrl,
  children,
  handleSubmit,
  handleInputChange,
  handleCheckboxChange,
  isFormEnabled,
  email,
  showProductCardBtn,
}) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Hero
      backgroundImageUrl={heroImageUrl}
      mobileBackgroundImageUrl={mobileHeroImageUrl}
    >
      <h1 style={{ color: "#fff" }}>{title}</h1>
      <div className="l-dbl-push-bottom l-bleed">{children}</div>
      {showProductCardBtn &&
        (showForm ? (
          <ProductCardForm
            closeForm={() => setShowForm(false)}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            isFormEnabled={isFormEnabled}
            email={email}
          />
        ) : (
          <button
            className="button button-ghost-negative"
            onClick={() => setShowForm(true)}
          >
            Download product card
          </button>
        ))}
    </Hero>
  );
};
