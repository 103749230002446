import React, { FC, CSSProperties } from "react";

export interface HeroProps {
  mobileBackgroundImageUrl?: string;
  backgroundImageUrl: string;
}

export interface CSSVariables extends CSSProperties {
  "--small-image": string;
  "--large-image": string;
}

export const Hero: FC<HeroProps> = ({
  mobileBackgroundImageUrl,
  backgroundImageUrl,
  children,
}) => (
  <div
    className="hero-image hero-image-compact color-bg-light"
    style={
      {
        paddingBottom: "2.5rem",
        backgroundPosition: "right center",
        backgroundImage: `url(${backgroundImageUrl})`,
        "--small-image": `url(${
          mobileBackgroundImageUrl || backgroundImageUrl
        })`,
        "--large-image": `url(${backgroundImageUrl})`,
      } as CSSVariables
    }
  >
    <div className="l-wrapper clearfix">
      <div className="hero-image-cf ontent hero-image-content-compressed l-6-cols-tablet color-negative">
        <div>{children}</div>
      </div>
    </div>
  </div>
);
