import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface ProcessStepProps {
  iconUrl: string;
  title: string;
  description: string;
}

export const ProcessStep: FC<ProcessStepProps> = ({
  iconUrl,
  title,
  description,
}) => (
  <li className="l-8-cols-desktop l-full l-island clearfix process-step-wrapper">
    <div className="process-step flex-box flex-dir-column">
      <div className="header flex-box flex-box-center-x flex-align-center position-rel">
        <img src={iconUrl} alt="" />
        <div className="title align-center">
          <strong>{title}</strong>
        </div>
      </div>
      <ReactMarkdown allowDangerousHtml>{description}</ReactMarkdown>
    </div>
  </li>
);
