import React, { FC } from "react";

export interface TextSectionProps {
  marginTop?: string;
}
export const TextSection: FC<TextSectionProps> = ({ children, marginTop }) => (
  <section className="l-wrapper clearfix">
    <div className="l-8-cols-desktop l-dbl-push-bottom l-island l-bleed clearfix l-push-top">
      <div
        className="l-full l-push-bottom text-section"
        style={{ marginTop: marginTop || "4rem" }}
      >
        {children}
      </div>
    </div>
  </section>
);
