import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";
import { MainLayout } from "@/layouts/MainLayout";
import { ProductCardWrapper } from "@/components/ProductCardWrapper";
import { QualitiesList } from "@/components/QualitiesList";
import { PageProps } from "gatsby";
import { TextSection } from "@/components/layout/TextSection";
import { HighlightBoxes } from "@/components/HighlightBoxes";
import { ProcessStep } from "@/components/ProcessStep";
import { CaseStudy } from "@/components/CaseStudy";
import { Link } from "@/components/basic/Link";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { SEO } from "@/components/basic/SEO";

const ProfessionalServicesSubpageTemplate: FC<PageProps<
  any,
  GatsbyTypes.Query_contentfulProfessionalServicesLandingPageArgs
>> = ({ pageContext }) => (
  <MainLayout>
    <Helmet>
      <body className="ltr professional-services-subpage" id="intro" />
    </Helmet>
    {pageContext.metaDescription && (
      <SEO
        title={pageContext.metaTitle}
        description={pageContext.metaDescription.metaDescription}
        keywords={pageContext.metaKeywords}
      />
    )}
    <ProductCardWrapper
      shortDescription={
        pageContext.shortDescription &&
        pageContext.shortDescription.shortDescription
      }
      descriptionTitle={pageContext.descriptionTitle}
      mobileHeroImageUrl={
        pageContext.mobileHeroImage && pageContext.mobileHeroImage.file.url
      }
      heroImageUrl={pageContext.heroImage.file.url}
      sendGridTemplateId={pageContext.sendGridTemplateId}
      showProductCardBtn={pageContext.productCard}
    />
    <div className="l-wrapper clearfix">
      {pageContext.description && (
        <TextSection>
          <ReactMarkdown allowDangerousHtml>
            {pageContext.description.description}
          </ReactMarkdown>
        </TextSection>
      )}
      {pageContext.ourSpecialisation && (
        <QualitiesList
          title={pageContext.specialisationTitle}
          markdown={pageContext.ourSpecialisation.ourSpecialisation}
        />
      )}
      {pageContext.specialisationDescription && (
        <TextSection>
          <ReactMarkdown>
            {pageContext.specialisationDescription.specialisationDescription}
          </ReactMarkdown>
        </TextSection>
      )}
      {pageContext.offerIntro && (
        <TextSection marginTop="4rem">
          <ReactMarkdown allowDangerousHtml>
            {pageContext.offerIntro.offerIntro}
          </ReactMarkdown>
        </TextSection>
      )}
      {pageContext.offerList && (
        <HighlightBoxes
          columns={8}
          className="flat"
          markdown={pageContext.offerList.offerList}
        />
      )}
      {pageContext.offerDescription && (
        <TextSection marginTop="0">
          <ReactMarkdown>
            {pageContext.offerDescription.offerDescription}
          </ReactMarkdown>
        </TextSection>
      )}
    </div>
    <section className="process-steps-section">
      {pageContext.processStepsIntro && (
        <div className="l-full text-section">
          <ReactMarkdown allowDangerousHtml>
            {pageContext.processStepsIntro.processStepsIntro}
          </ReactMarkdown>
        </div>
      )}
      {pageContext.processStepsImage && (
        <TextSection marginTop="0">
          <div className="image-object">
            <img
              alt={pageContext.processStepsImage.description || ""}
              src={pageContext.processStepsImage.file.url}
            />
          </div>
        </TextSection>
      )}
      {pageContext.processSteps && (
        <ul className="l-wrapper process-steps">
          {pageContext.processSteps.map(
            ({ title, icon: { file }, description: { description } }) => (
              <ProcessStep
                title={title}
                description={description}
                iconUrl={file.url}
                key={title}
              />
            )
          )}
        </ul>
      )}
    </section>
    {pageContext.benefits && (
      <section style={{ marginTop: "4rem" }}>
        {pageContext.benefitsTitle && (
          <h3 className="color-primary align-center l-push-bottom">
            {pageContext.benefitsTitle}
          </h3>
        )}
        <HighlightBoxes markdown={pageContext.benefits.benefits} />
      </section>
    )}
    {pageContext.highlights && (
      <section style={{ marginTop: "4rem" }}>
        <QualitiesList
          title={pageContext.highlightsTitle}
          markdown={pageContext.highlights.highlights}
        />
      </section>
    )}
    {pageContext.caseStudies && (
      <section className="case-studies-section">
        {pageContext.caseStudiesIntro && (
          <TextSection marginTop="4rem">
            <ReactMarkdown allowDangerousHtml>
              {pageContext.caseStudiesIntro.caseStudiesIntro}
            </ReactMarkdown>
          </TextSection>
        )}
        <ul>
          {pageContext.caseStudies.map(({ image, description }) => (
            <CaseStudy
              imageUrl={image.file.url}
              imageAlt={image.description}
              description={description.description}
              key={description.description}
            />
          ))}
        </ul>
      </section>
    )}
    {pageContext.summary && (
      <TextSection marginTop="4em">
        <ReactMarkdown>{pageContext.summary.summary}</ReactMarkdown>
        <div className="l-with-gutter-top-mobile align-center">
          Want to know more?
        </div>
        <div className="align-center l-push-top">
          <a
            className="button button button-ghost-primary"
            href="/contact#contact-form"
          >
            SEND US A MESSAGE
          </a>
        </div>
      </TextSection>
    )}
    <SiteFooterWrapper>
      <SiteFooterNewsletterButton />
      <Link
        classname="button button-quaternary-negative-dark button-estimate-project"
        url="/estimate-project"
        text="estimate project"
      />
    </SiteFooterWrapper>
  </MainLayout>
);

export default ProfessionalServicesSubpageTemplate;
