import React, { FC } from "react";
import ReactMarkdown from "react-markdown";

export interface HighlightBoxesProps {
  title?: string;
  markdown: string;
}
export const QualitiesList: FC<HighlightBoxesProps> = ({ title, markdown }) => (
  <div className="l-wrapper">
    <div className="l-8-cols-desktop l-island l-bleed l-dbl-push-bottom clearfix l-dbl-push-bottom qualities-list">
      {title && (
        <h3 className="color-primary align-center l-push-bottom title">
          {title}
        </h3>
      )}
      <ReactMarkdown allowDangerousHtml={true}>{markdown}</ReactMarkdown>
    </div>
  </div>
);
