import React, { FC, useState } from "react";
import { NewsletterForm } from "@/components/NewsletterForm";

export const SiteFooterNewsletterButton: FC = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {!showForm ? (
        <button
          onClick={() => setShowForm(true)}
          className="button button-primary-negative-dark button-newsletter"
          type="button"
        >
          Newsletter
        </button>
      ) : (
        <NewsletterForm onClose={() => setShowForm(false)} />
      )}
    </>
  );
};
